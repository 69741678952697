<!-- 账单内容页页 -->
<template>
  <div>
    <blockTitle title="账单内容页"></blockTitle>
    <div
        v-if="billObj.payState === 2"
        class="opacity-80 text-3xl text-green-800 rounded-full border-4 border-green-800 w-40 h-40 fixed z-10 flex flex-col align-items-center justify-content-center right-32 top-36 select-none transform rotate-12 bg-white shadow-2xl"
    >
      <div
          class="font-black font-sans absolute top-4 z-20 border-4 flex align-items-center justify-center border-green-900 bg-white w-52 h-14 text-center"
      >
        账单已支付
      </div>
      <div class="text-xs text-gray-500 mt-14 font-black font-sans">
        对公转账
      </div>
      <div class="text-xs text-gray-500 mt-1">
        {{ billObj.payDtm.split(' ')[0] }}
      </div>
      <div class="text-xs text-gray-500">
        {{ billObj.payDtm.split(' ')[1] }}
      </div>
    </div>
    <div
        v-if="billObj.payState === 1"
        class="opacity-80 text-4xl text-yellow-300 rounded-full border-4 border-yellow-300 w-36 h-36 fixed z-10 flex align-items-center justify-content-center right-32 top-36 select-none transform rotate-12 bg-white"
    >
      <div class="font-black font-sans">未支付</div>
    </div>
    <el-card shadow="never">
      <div class="card-content">
        <div class="text-xl font-bold pt-2 pb-4 border-b mb-4">
          {{ billObj.billNo }} 账单明细
        </div>
        <div
            class="text-lg font-bold mb-2 border-b border-gray-200 border-dashed pb-2"
        >
          客户信息
        </div>
        <el-form
            ref="form"
            :model="billObj"
            label-width="160px"
            label-position="left"
            size="small"
            cass="overflow-auto"
        >
          <el-form-item label="客户">
            <div>
              {{ customerInfo.name }}
            </div>
          </el-form-item>
          <el-form-item label="联系人">
            <div>
              {{ customerInfo.contactName || '暂未填写联系人' }}
            </div>
          </el-form-item>
          <el-form-item label="联系方式">
            <div>
              {{ customerInfo.contactPhone || '暂未填写联系方式' }}
            </div>
          </el-form-item>
        </el-form>
        <div
            class="text-lg font-bold mb-2 border-b border-gray-200 border-dashed pb-2 mt-8"
        >
          账单内容
        </div>
        <el-table
            :summary-method="getSum"
            show-summary
            :data="billProject"
            style="width: 100%"
        >
          <el-table-column prop="projectType" label="项目类型">
            <template v-slot="{ row }">
              {{
                $store.getters.projectTypeListFilter({
                  projectType: row.projectType
                }).label
              }}
            </template>
          </el-table-column>
          <el-table-column prop="billProjectId" label="产品规格">
            <template v-slot="{ row }">
              <div>{{ row.projectName }}</div>
            </template>
          </el-table-column>
          <el-table-column label="项目单价">
            <template v-slot="{ row }">
              {{ getProjectItem(row.billProjectId).unitPrice }}元 /
              {{ getProjectItem(row.billProjectId).unit }}
            </template>
          </el-table-column>
          <el-table-column prop="quantity" label="数量">
            <template v-slot="{ row }">
              {{ row.assignNum }} / {{ row.quantity }}
            </template>
          </el-table-column>
          <el-table-column prop="totalFee" label="总金额">
            <template v-slot="{ row }">
              {{ row.totalFee }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <el-card shadow="never" class="mt-4">
      <div
          class="card-content flex justify-between align-items-center pt-2 pb-4 border-b mb-4"
      >
        <div class="text-xl font-bold">账单使用情况</div>
        <el-input
            class="w-60"
            v-model="searchValue"
            placeholder="请输入imei号搜索"
            size="small"
            clearable
        ></el-input>
      </div>
      <el-table
          class="text-sm"
          :data="
          billImeiList.filter(
            (data) =>
              !searchValue ||
              data.imei.toLowerCase().includes(searchValue.toLowerCase())
          )
        "
          height="50vh"
          style="width: 100%"
      >
        <el-table-column prop="imei" label="IMEI"></el-table-column>
        <el-table-column
            prop="deviceType"
            :filters="deviceTypeList"
            :filter-method="deviceTypeFilter"
            label="设备类型"
        >
          <template v-slot="{ row }">
            {{
              row.deviceType
                  ? deviceTypeListExcludeAllType.find(
                      (item) => item.deviceType === row.deviceType
                  ).deviceTypeName
                  : ''
            }}
          </template>
        </el-table-column>
        <el-table-column
            prop="deviceModel"
            :filters="deviceModelList"
            :filter-method="deviceModelFilter"
            label="设备型号"
        >
        </el-table-column>
        <el-table-column
            prop="billProjectName"
            :filters="billProjectNameList"
            :filter-method="billProjectNameFilter"
            label="产品规格"
        >
        </el-table-column>
        <el-table-column prop="bindDtm" label="绑定时间"></el-table-column>
        <el-table-column prop="failureDtm" label="失效时间"></el-table-column>
        <el-table-column
            prop="bindState"
            :filters="bindStateFilterList"
            :filter-method="bindStateFilter"
            label="绑定状态"
        >
          <template v-slot="{ row }">
            <template v-if="row.bindState === 1"> 首次绑定</template>
            <template v-if="row.bindState === 0"> 重复授权</template>
          </template>
        </el-table-column>
        <el-table-column prop="createName" label="操作人"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      billId: '',
      billObj: {},
      customerInfo: {},
      billProject: [],
      allBillProjectList: [],
      searchValue: '',
      billImeiList: [],
      bindStateFilterList: [
        {
          value: 1,
          text: '仅显示首次绑定'
        },
        {
          value: 0,
          text: '仅显示重复授权'
        }
      ]
    }
  },
  props: {
    parentBillId: {
      type: Number
    }
  },
  computed: {
    ...mapGetters(['deviceTypeListExcludeAllType']),
    billRelationList () {
      const arr = []
      // this.billProject.forEach(item => {
      //   item.billRelationList.forEach((billRelationItem) => {
      //     billRelationItem.projectName = item.projectName
      //     arr.push(billRelationItem)
      //   })
      // })
      return arr
    },
    billProjectNameList () {
      const projectNameList = []
      this.billImeiList.forEach(item => {
        if (projectNameList.find(existItem => item.billProjectName === existItem.value)) {
          return false
        } else {
          projectNameList.push({
            text: item.billProjectName,
            value: item.billProjectName
          })
        }
      })
      return projectNameList
    },
    deviceModelList () {
      const deviceModelList = []
      this.billImeiList.forEach(item => {
        if (deviceModelList.find(existItem => item.deviceModel === existItem.value)) {
          return false
        } else {
          deviceModelList.push({
            text: item.deviceModel,
            value: item.deviceModel
          })
        }
      })
      return deviceModelList
    },
    deviceTypeList () {
      const deviceTypeList = []
      this.billImeiList.forEach(item => {
        if (deviceTypeList.find(existItem => item.deviceType === existItem.value)) {
          return false
        } else {
          if (item.deviceType) {
            deviceTypeList.push({
              text: (this.deviceTypeListExcludeAllType.find(deviceTypeItem => deviceTypeItem.deviceType === item.deviceType)).deviceTypeName,
              value: item.deviceType
            })
          }
        }
      })
      return deviceTypeList
    }
  },
  methods: {
    initData () {
      this.billObj = {
        agUserKey: '',
        payType: 1,
        projectList: [],
        remark: '',
        billNo: ''
      }
    },
    getData () {
      this.$api.select('bill', this.billId).then(res => {
        this.billObj = res.data
        this.billProject = res.data.billDetailList
        this.getUserData(res.data.uniqueId)
        this.getBillImeiList(this.billObj.billNo)
      })
    },
    getUserData (uniqueId) {
      this.$api.customerInfo.getUserByUniqueId({ uniqueId }).then(res => {
        this.customerInfo = res.data
      })
    },
    /**
     * 计算当行总价
     * @param index
     */
    computedRowMoney (index) {
      const item = this.billObj.projectList[index]
      if (item) {
        const unitPrice = this.getProjectItem(item.billProjectId).unitPrice
        item.totalFee = unitPrice * item.quantity
      }
    },
    getProjectItem (billProjectId) {
      const item = this.allBillProjectList.find(item => item.id === billProjectId)
      if (item) {
        return item
      }
      return {
        unitPrice: '-'
      }
    },
    /**
     * 计算合计
     */
    getSum ({
      columns,
      data
    }) {
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价'
          return
        }
        if (column.property === 'totalFee') {
          if (data.length > 0) {
            const totalFeeArr = data.map(item => item.totalFee)
            sums[index] = totalFeeArr.reduce((prev, curr, index) => {
              const row = data[index]
              if (row.billProjectId) {
                // 如果没有选择产品规格 不参与合计计算
                return prev + curr
              } else {
                return prev
              }
            })
            sums[index] += ' 元'
          }
        }
      })
      return sums
    },
    getBillProjectList () {
      this.$api.findAll('billProject').then(res => {
        this.allBillProjectList = res.data
      })
    },
    getBillImeiList (billNo) {
      this.$api.billImei.findAll({
        billNo
      }).then(res => {
        this.billImeiList = res.data
      })
    },
    bindStateFilter (value, row) {
      return row.bindState === value
    },
    billProjectNameFilter (value, row) {
      return row.billProjectName === value
    },
    deviceModelFilter (value, row) {
      return row.deviceModel === value
    },
    deviceTypeFilter (value, row) {
      return row.deviceType === value
    }
  },
  mounted () {
    if (this.$route.name === 'billContent') {
      this.billId = this.$route.query.id
    } else {
      this.billId = this.parentBillId
    }
    if (this.billId) {
      this.getBillProjectList()
      this.getData()
    }
  },
  beforeRouteLeave (to, from, next) {
    to.meta.isBack = to.name === 'clientDetails'
    next()
  }

}
</script>
